import { createAction, createAsyncAction } from "typesafe-actions"
import {
    LoginActionTypes,
    LoginRequest,
    LogoutActionType,
    ResendConfirmEmailActionTypes,
    ResetPasswordActionTypes,
    ResetPasswordConfirmationActionTypes,
    ResetPasswordConfirmCodeRequest,
    resetPasswordProps,
    ResetPasswordRequest,
    SendConfirmCodeActionTypes,
    SendConfirmCodeRequest,
    SendConfirmTokenActionTypes,
    SendConfirmTokenRequest,
    SetNewPasswordActionTypes,
    SetNewPasswordRequest,
    SetUserActionTypes,
    ShareByEmailActionTypes,
    SignUpActionTypes,
    SignUpRequest,
    SsoSignOnActionTypes,
    UpdateAvatarActionTypes,
    UpdatePasswordActionTypes,
    UpdateUserNameActionTypes,
    UserResponse,
    PopupActionType,
    UnsubscribeActionTypes,
    UnsubscribeRequest,
} from "./types"
import { FormikErrors, FormikState } from "formik"
import { Dispatch, SetStateAction } from "react"

export const loginActions = createAsyncAction(
    LoginActionTypes.LOGIN_REQUEST,
    LoginActionTypes.LOGIN_SUCCESS,
    LoginActionTypes.LOGIN_FAILURE
)<
    LoginRequest & { setErrors: (errors: FormikErrors<LoginRequest>) => void },
    UserResponse,
    { error: any }
>()

export const LogoutAction = createAction(LogoutActionType.LOGOUT)<undefined>()

export const signUpActions = createAsyncAction(
    SignUpActionTypes.SIGN_UP_REQUEST,
    SignUpActionTypes.SIGN_UP_SUCCESS,
    SignUpActionTypes.SIGN_UP_FAILURE
)<
    SignUpRequest & {
        setErrors: (errors: FormikErrors<SignUpRequest>) => void
    },
    UserResponse,
    { error: any }
>()

export const ssoSignOnActions = createAsyncAction(
    SsoSignOnActionTypes.SSO_SIGN_ON_REQUEST,
    SsoSignOnActionTypes.SSO_SIGN_ON_SUCCESS,
    SsoSignOnActionTypes.SSO_SIGN_ON_FAILURE
)<
    {
        token?: string
        oauth_token?: string | string[]
        oauth_verifier?: string | string[]
        type: "facebook" | "twitter"
    },
    UserResponse,
    { error: any }
>()

export const sendConfirmCodeActions = createAsyncAction(
    SendConfirmCodeActionTypes.SEND_CONFIRM_CODE_REQUEST,
    SendConfirmCodeActionTypes.SEND_CONFIRM_CODE_SUCCESS,
    SendConfirmCodeActionTypes.SEND_CONFIRM_CODE_FAILURE
)<
    SendConfirmCodeRequest & { setErrors: (errors: FormikErrors<SendConfirmCodeRequest>) => void },
    UserResponse,
    { error: any }
>()

export const sendConfirmTokenActions = createAsyncAction(
    SendConfirmTokenActionTypes.SEND_CONFIRM_TOKEN_REQUEST,
    SendConfirmTokenActionTypes.SEND_CONFIRM_TOKEN_SUCCESS,
    SendConfirmTokenActionTypes.SEND_CONFIRM_TOKEN_FAILURE
)<SendConfirmTokenRequest & { router: any }, UserResponse, { error: any }>()

export const unsubscribeActions = createAsyncAction(
    UnsubscribeActionTypes.UNSUBSCRIBE_REQUEST,
    UnsubscribeActionTypes.UNSUBSCRIBE_SUCCESS,
    UnsubscribeActionTypes.UNSUBSCRIBE_FAILURE
)<UnsubscribeRequest & { router: any }, undefined, { error: any }>()

export const resetPasswordActions = createAsyncAction(
    ResetPasswordActionTypes.RESET_PASSWORD_REQUEST,
    ResetPasswordActionTypes.RESET_PASSWORD_SUCCESS,
    ResetPasswordActionTypes.RESET_PASSWORD_FAILURE
)<
    ResetPasswordRequest & {
        setErrors?: (errors: FormikErrors<ResetPasswordRequest>) => void
        skipSignIn?: boolean
    },
    undefined,
    { error: any }
>()

export const resetPasswordConfirmationActions = createAsyncAction(
    ResetPasswordConfirmationActionTypes.RESET_PASSWORD_CONFIRMATION_REQUEST,
    ResetPasswordConfirmationActionTypes.RESET_PASSWORD_CONFIRMATION_SUCCESS,
    ResetPasswordConfirmationActionTypes.RESET_PASSWORD_CONFIRMATION_FAILURE
)<
    ResetPasswordConfirmCodeRequest & {
        setErrors: (errors: FormikErrors<ResetPasswordConfirmCodeRequest>) => void
    },
    undefined,
    { error: any }
>()

export const setNewPasswordActions = createAsyncAction(
    SetNewPasswordActionTypes.SET_NEW_PASSWORD_REQUEST,
    SetNewPasswordActionTypes.SET_NEW_PASSWORD_SUCCESS,
    SetNewPasswordActionTypes.SET_NEW_PASSWORD_FAILURE
)<
    SetNewPasswordRequest & { setErrors: (errors: FormikErrors<SetNewPasswordRequest>) => void },
    undefined,
    { error: any }
>()

export const resendConfirmEmailActions = createAsyncAction(
    ResendConfirmEmailActionTypes.RESEND_CONFIRM_EMAIL_REQUEST,
    ResendConfirmEmailActionTypes.RESEND_CONFIRM_EMAIL_SUCCESS,
    ResendConfirmEmailActionTypes.RESEND_CONFIRM_EMAIL_FAILURE
)<{ email: string }, undefined, { error: any }>()

export const updateUserNameActions = createAsyncAction(
    UpdateUserNameActionTypes.UPDATE_USER_NAME_REQUEST,
    UpdateUserNameActionTypes.UPDATE_USER_NAME_SUCCESS,
    UpdateUserNameActionTypes.UPDATE_USER_NAME_FAILURE
)<
    {
        name: string
        setErrors: (errors: FormikErrors<{ name: string }>) => void
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
        setEditName: Dispatch<SetStateAction<boolean>>
    },
    { name: string },
    { error: any }
>()

export const updatePasswordActions = createAsyncAction(
    UpdatePasswordActionTypes.UPDATE_PASSWORD_REQUEST,
    UpdatePasswordActionTypes.UPDATE_PASSWORD_SUCCESS,
    UpdatePasswordActionTypes.UPDATE_PASSWORD_FAILURE
)<
    {
        password: string
        oldPassword: string
        resetForm: (nextState?: Partial<FormikState<resetPasswordProps>> | undefined) => void
        setErrors: (errors: FormikErrors<resetPasswordProps>) => void
        setEditPassword: Dispatch<SetStateAction<boolean>>
    },
    null,
    { error: any }
>()

export const updateAvatarActions = createAsyncAction(
    UpdateAvatarActionTypes.UPDATE_AVATAR_REQUEST,
    UpdateAvatarActionTypes.UPDATE_AVATAR_SUCCESS,
    UpdateAvatarActionTypes.UPDATE_AVATAR_FAILURE
)<{ image: File }, { link: string }, { error: any }>()

export const shareByEmailActions = createAsyncAction(
    ShareByEmailActionTypes.SHARE_BY_EMAIL_REQUEST,
    ShareByEmailActionTypes.SHARE_BY_EMAIL_SUCCESS,
    ShareByEmailActionTypes.SHARE_BY_EMAIL_FAILURE
)<
    {
        from: string
        to: string
        subject: string
        message: string
        body: string | null | undefined
        afterSend?: () => void
    },
    null,
    { error: any }
>()

export const setUserAction = createAction(SetUserActionTypes.SET_USER)<UserResponse>()

export const showPopup = createAction(PopupActionType.SHOW_POPUP)<boolean>()

export const removePopup = createAction(PopupActionType.REMOVE_POPUP)()
