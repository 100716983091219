import { ActionType } from "typesafe-actions"
import * as userActions from "./user"

export type resetPasswordProps = { password: string; oldPassword: string; confirmPassword: string }

export type UserReducerState = {
    email: string
    loading: boolean
    loadingWholePage: boolean
    image: string
    link: string
    name: string
    access_token: string
    isGuest: boolean
    isAdmin?: boolean
    popup: boolean | null
}

export type SignUpRequest = {
    name: string
    email: string
    password: string
    guestMode: boolean
}

export type LoginRequest = {
    email: string
    password: string
}

export type SendConfirmCodeRequest = {
    email: string
    confirmCode: string
}

export type SendConfirmTokenRequest = {
    confirm_token: string
}

export type UnsubscribeRequest = {
    unsubscribe_token: string
}

export type ResetPasswordRequest = {
    email: string
}

export type ResetPasswordConfirmCodeRequest = {
    code: string
    email: string
}

export type SetNewPasswordRequest = {
    password: string
    reset_password_token: string
}

export type UserResponse = {
    email: string
    name: string
    image: string
    link: string
    access_token?: string
}

export enum LoginActionTypes {
    LOGIN_REQUEST = "@@user/LOGIN_REQUEST",
    LOGIN_SUCCESS = "@@user/LOGIN_SUCCESS",
    LOGIN_FAILURE = "@@user/LOGIN_FAILURE",
}

export enum LogoutActionType {
    LOGOUT = "@@user/LOGOUT_REQUEST",
}

export enum SignUpActionTypes {
    SIGN_UP_REQUEST = "@@user/SIGN_UP_REQUEST",
    SIGN_UP_SUCCESS = "@@user/SIGN_UP_SUCCESS",
    SIGN_UP_FAILURE = "@@user/SIGN_UP_FAILURE",
}

export enum SsoSignOnActionTypes {
    SSO_SIGN_ON_REQUEST = "@@user/SSO_SIGN_ON_REQUEST",
    SSO_SIGN_ON_SUCCESS = "@@user/SSO_SIGN_ON_SUCCESS",
    SSO_SIGN_ON_FAILURE = "@@user/SSO_SIGN_ON_FAILURE",
}

export enum SendConfirmCodeActionTypes {
    SEND_CONFIRM_CODE_REQUEST = "@@user/SEND_CONFIRM_CODE_REQUEST",
    SEND_CONFIRM_CODE_SUCCESS = "@@user/SEND_CONFIRM_CODE_SUCCESS",
    SEND_CONFIRM_CODE_FAILURE = "@@user/SEND_CONFIRM_CODE_FAILURE",
}

export enum SendConfirmTokenActionTypes {
    SEND_CONFIRM_TOKEN_REQUEST = "@@user/SEND_CONFIRM_TOKEN_REQUEST",
    SEND_CONFIRM_TOKEN_SUCCESS = "@@user/SEND_CONFIRM_TOKEN_SUCCESS",
    SEND_CONFIRM_TOKEN_FAILURE = "@@user/SEND_CONFIRM_TOKEN_FAILURE",
}

export enum UnsubscribeActionTypes {
    UNSUBSCRIBE_REQUEST = "@@user/UNSUBSCRIBE_REQUEST",
    UNSUBSCRIBE_SUCCESS = "@@user/UNSUBSCRIBE_SUCCESS",
    UNSUBSCRIBE_FAILURE = "@@user/UNSUBSCRIBE_FAILURE",
}

export enum ResetPasswordActionTypes {
    RESET_PASSWORD_REQUEST = "@@user/RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_SUCCESS = "@@user/RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILURE = "@@user/RESET_PASSWORD_FAILURE",
}

export enum ResetPasswordConfirmationActionTypes {
    RESET_PASSWORD_CONFIRMATION_REQUEST = "@@user/RESET_PASSWORD_CONFIRMATION_REQUEST",
    RESET_PASSWORD_CONFIRMATION_SUCCESS = "@@user/RESET_PASSWORD_CONFIRMATION_SUCCESS",
    RESET_PASSWORD_CONFIRMATION_FAILURE = "@@user/RESET_PASSWORD_CONFIRMATION_FAILURE",
}

export enum SetNewPasswordActionTypes {
    SET_NEW_PASSWORD_REQUEST = "@@user/SET_NEW_PASSWORD_REQUEST",
    SET_NEW_PASSWORD_SUCCESS = "@@user/SET_NEW_PASSWORD_SUCCESS",
    SET_NEW_PASSWORD_FAILURE = "@@user/SET_NEW_PASSWORD_FAILURE",
}

export enum ResendConfirmEmailActionTypes {
    RESEND_CONFIRM_EMAIL_REQUEST = "@@user/RESEND_CONFIRM_EMAIL_REQUEST",
    RESEND_CONFIRM_EMAIL_SUCCESS = "@@user/RESEND_CONFIRM_EMAIL_SUCCESS",
    RESEND_CONFIRM_EMAIL_FAILURE = "@@user/RESEND_CONFIRM_EMAIL_FAILURE",
}

export enum UpdateUserNameActionTypes {
    UPDATE_USER_NAME_REQUEST = "@@user/UPDATE_USER_NAME_REQUEST",
    UPDATE_USER_NAME_SUCCESS = "@@user/UPDATE_USER_NAME_SUCCESS",
    UPDATE_USER_NAME_FAILURE = "@@user/UPDATE_USER_NAME_FAILURE",
}

export enum UpdatePasswordActionTypes {
    UPDATE_PASSWORD_REQUEST = "@@user/UPDATE_PASSWORD_REQUEST",
    UPDATE_PASSWORD_SUCCESS = "@@user/UPDATE_PASSWORD_SUCCESS",
    UPDATE_PASSWORD_FAILURE = "@@user/UPDATE_PASSWORD_FAILURE",
}

export enum UpdateAvatarActionTypes {
    UPDATE_AVATAR_REQUEST = "@@user/UPDATE_AVATAR_REQUEST",
    UPDATE_AVATAR_SUCCESS = "@@user/UPDATE_AVATAR_SUCCESS",
    UPDATE_AVATAR_FAILURE = "@@user/UPDATE_AVATAR_FAILURE",
}

export enum ShareByEmailActionTypes {
    SHARE_BY_EMAIL_REQUEST = "@@user/SHARE_BY_EMAIL_REQUEST",
    SHARE_BY_EMAIL_SUCCESS = "@@user/SHARE_BY_EMAIL_SUCCESS",
    SHARE_BY_EMAIL_FAILURE = "@@user/SHARE_BY_EMAIL_FAILURE",
}

export enum SetUserActionTypes {
    SET_USER = "@@user/SET_USER",
}

export enum PopupActionType {
    SHOW_POPUP = "@@ui/SHOW_POPUP",
    REMOVE_POPUP = "@@ui/REMOVE_POPUP",
}

export type UserActions = ActionType<typeof userActions>
